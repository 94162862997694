import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private googleAuthUrl = 'https://accounts.google.com/o/oauth2/auth';
  private clientId = '32155575098-phku9lhug6c64b6lk1r8ktc1opbmr80q.apps.googleusercontent.com'; 
  private redirectUri = 'http://localhost:4200/auth/callback'; 
  private responseType = 'token';
  private scope = 'https://www.googleapis.com/auth/tagmanager.readonly https://www.googleapis.com/auth/tagmanager.edit.containers https://www.googleapis.com/auth/tagmanager.publish';
  private accessToken: string | null = null;

  constructor(private http: HttpClient) { }

  getAuthUrl(): string {
    return `${this.googleAuthUrl}?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=${this.responseType}&scope=${encodeURIComponent(this.scope)}`;
  }

  handleAuthCallback(): void {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const accessToken = params.get('access_token');
    console.log('Access Token from URL:', accessToken);  // Log do token para verificar o que está sendo recebido
  
    if (accessToken) {
      this.setAccessToken(accessToken);
      localStorage.setItem('openGtmModal', 'true'); 
      console.log('openGtmModal set to true');
    } else {
      console.log('No access token found in the URL.');  // Log se nenhum token foi encontrado
    }
  }
  
  
 setAccessToken(token: string): void {
    this.accessToken = token;
    localStorage.setItem('gtm_access_token', token);
  }
  getAccessToken(): string | null {
    return localStorage.getItem('gtm_access_token');
  }
}
